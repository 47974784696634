$(document).on('turbolinks:load', function () {
  const toggleToChild = document.getElementById('toggle-to-child');
  const toggleToParent = document.getElementById('toggle-to-parent');
  const pointTableChild_01 = document.getElementById('point-table-child-01');
  const pointTableChild_02 = document.getElementById('point-table-child-02');
  const pointTableParent_01 = document.getElementById('point-table-parent-01');
  const pointTableParent_02 = document.getElementById('point-table-parent-02');

  if (toggleToChild && toggleToParent &&
      pointTableChild_01 && pointTableParent_01 &&
      pointTableChild_02 && pointTableParent_02) {

    toggleToChild.addEventListener('click', function() {
      toggleToChild.classList.add('active');
      toggleToParent.classList.remove('active');
      pointTableChild_01.style.display = 'table';
      pointTableChild_02.style.display = 'table';
      pointTableParent_01.style.display = 'none';
      pointTableParent_02.style.display = 'none';
    });

    toggleToParent.addEventListener('click', function() {
      toggleToParent.classList.add('active');
      toggleToChild.classList.remove('active');
      pointTableParent_01.style.display = 'table';
      pointTableParent_02.style.display = 'table';
      pointTableChild_01.style.display = 'none';
      pointTableChild_02.style.display = 'none';
    });

    // 初期表示設定
    pointTableChild_01.style.display = 'table';
    pointTableChild_02.style.display = 'table';
    pointTableParent_01.style.display = 'none';
    pointTableParent_02.style.display = 'none';
  }
});